<template>
  <div when-visible id="hand-crafted-by">
    <div class="image-block">
      <template v-if="videoInsteadOfImage">
        <HomepageVideo
          v-if="data.video"
          class="image-block__image"
          video-ref="handcrafted-block-video"
          :data="data.video"
          :squareVideo="true"
        />
      </template>
      <template v-else>
        <nuxt-img
          v-if="data.imageMobile"
          class="image-block__image smartphone-only"
          :src="data.imageMobile"
          :alt="data.imageAlt"
        />
        <nuxt-img
          v-if="data.imageDesktop"
          :class="`image-block__image desktop-only ${data.textPosition}`"
          :src="data.imageDesktop"
          :alt="data.imageAlt"
        />
      </template>
      <div class="content-block smartphone-only">
        <div class=""></div>
        <div class="title-block">
          <span class="title-block__label">{{ data.titleMobile }}</span>
        </div>
        <div :class="`content-block__wrapper ${data.textPosition}`">
          <span class="content-block__wrapper__description">{{
            data.description
          }}</span>
          <CustomCta
            v-if="data.link"
            class="content-block__wrapper__cta"
            :link="localePath(data.link)"
          >
            <span class="content-block__wrapper__cta__label">{{
              data.cta
            }}</span>
          </CustomCta>
          <CustomCta
            v-if="showModalButton"
            class="content-block__wrapper__cta"
            @click="openModal('about')"
          >
            <span class="content-block__wrapper__cta__label">
              {{ $t('Request an Appointment') }}
            </span>
          </CustomCta>
        </div>
      </div>
      <div :class="`content-block desktop-only ${data.textPosition}`">
        <div class="title-block">
          <span class="title-block__label">{{ data.titleDesktop }}</span>
        </div>
        <div class="content-block__wrapper">
          <div>
            <span class="content-block__wrapper__description">{{
              data.description
            }}</span>
            <CustomCta
              v-if="data.link"
              class="content-block__wrapper__cta"
              :link="localePath(data.link)"
            >
              <span class="content-block__wrapper__cta__label">{{
                data.cta
              }}</span>
            </CustomCta>
            <CustomCta
              v-if="showModalButton"
              class="content-block__wrapper__cta"
              @click="openModal('about')"
            >
              <span class="content-block__wrapper__cta__label">
                {{ $t('Request an Appointment') }}
              </span>
            </CustomCta>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="module">
import HomepageVideo from '~/components/Homepage/HomepageVideo.vue';
import { defineComponent, ref } from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';
import CustomCta from '../General/CustomCta.vue';
import LazyHydrate from 'vue-lazy-hydration';
import { useUiState } from '~/composables';

export default defineComponent({
  name: 'HandCraftedBlock',
  components: {
    CustomCta,
    LazyHydrate,
    HomepageVideo,
  },
  props: {
    handcraftedData: {
      type: Object,
      default: () => {},
    },
    videoInsteadOfImage: {
      type: Boolean,
      default: false,
    },
    showModalButton: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const handcraftedDataContent = ref(props.handcraftedData);
    const { openModal } = useUiState();
    const data = ref({
      imageDesktop: handcraftedDataContent.value?.image?.desktop,
      imageMobile: handcraftedDataContent.value?.image?.mobile,
      imageAlt: handcraftedDataContent.value?.image?.alt,
      video: handcraftedDataContent.value?.video,
      titleDesktop: handcraftedDataContent.value?.title,
      titleMobile: handcraftedDataContent.value?.title,
      description: handcraftedDataContent.value?.description,
      cta:
        handcraftedDataContent.value?.link &&
        handcraftedDataContent.value?.link[`link-label`],
      target:
        handcraftedDataContent.value?.link &&
        handcraftedDataContent.value[`link-target`],
      textPosition:
        handcraftedDataContent.value &&
        handcraftedDataContent.value[`text-position`],
      link:
        handcraftedDataContent.value?.link &&
        handcraftedDataContent.value?.link[`link-url`],
      imagePosition:
        handcraftedDataContent.value &&
        handcraftedDataContent.value['image-position'],
    });
    return {
      addBasePath,
      openModal,
      data,
    };
  },
});
</script>

<style lang="scss" scoped>
#hand-crafted-by {
  .image-block {
    min-height: 44.6875rem;
    max-height: 47.9375rem;
    background-color: var(--c-black);
    position: relative;
    &__image {
      display: block;
      width: 100%;
      object-fit: cover;
      max-height: 47.9375rem;
      img {
        width: 100%;
        max-height: 48rem;
      }
    }
    .content-block {
      position: absolute;
      z-index: 1;
      display: block;
      width: 100%;
      height: auto;
      bottom: 60px;
      .title-block {
        padding-left: 1.25rem;
        &__label {
          @include mobile-h3;
          color: var(--c-white);
        }
      }
      &__wrapper {
        width: 100%;
        height: auto;
        &.left {
          padding: 1.875rem 4.875rem 0 1.25rem;
        }
        &.right {
          padding: 1.875rem 1.25rem 0 4.875rem;
        }
        &__description {
          display: block;
          @include paragraph-m;
          color: var(--c-white);
          width: 73.87%;
          padding-bottom: 1.25rem;
        }
      }
    }
  }
}
@include from-desktop-min {
  #hand-crafted-by {
    width: 100%;
    .image-block {
      width: 100%;
      height: 100%;
      max-height: none;
      background-color: var(--c-black);
      position: relative;
      &__wrapper {
        height: 100%;
      }
      &__image {
        display: block;
        width: 50%;
        height: 100%;
        object-fit: cover;
        height: 100%;
        min-height: 100%;
        &.left {
          margin-right: 0;
          margin-left: auto;
        }
        &.right {
          margin-right: auto;
          margin-left: 0;
        }
      }
      .content-block {
        position: absolute;
        z-index: 1;
        display: block;
        width: 59%;
        height: auto;
        top: 3.75rem;
        .title-block {
          padding-left: 0;
          &__label {
            @include desktop-h3;
            color: var(--c-white);
          }
        }
        &__wrapper {
          width: 100%;
          height: auto;
          &__description {
            margin-right: 0;
            margin-left: auto;
            display: block;
            @include paragraph-m;
            color: var(--c-white);
            width: 43.429%;
            padding-top: 20px;
            padding-bottom: 1.25rem;
          }
          &__cta {
            margin-right: auto;
            margin-left: 56.571%;
          }
        }
        &.left {
          padding: 0 0 0 9.5%;
          .content-block__wrapper__description {
            margin-left: 0;
          }
          .content-block__wrapper__cta {
            margin-left: 0;
          }
        }
        &.right {
          padding: 0 0 0 34%;
        }
      }
    }
  }
}
</style>
